import axios from 'axios';
import {Message} from 'view-design';
import config from '@/config';
import router from '@/router'
// import {getCookies,removeCookies} from '@/utils/helper'

const service = axios.create({
    baseURL: config.apiUrl + '/api',
    timeout: 10000 // 请求超时时间
})

service.interceptors.request.use(
    // config=>{
    //     const token = getCookies('hlzw_canyin_token')
    //     if(token){
    //         config.headers['hlzw-canyin-authori-zation'] = token
    //     }
    //     return config;
    // },
    // error=>{
    //     return Promise.reject(error);
    // }
)

service.interceptors.response.use(
    response=>{
        let status = response.data ? response.data.code : 0
        const code = status
        switch (code) {
            case 200:
                return response.data
            case 401:
            case 400:
                return Promise.reject( response.data || { msg: '未知错误' })
            case 400011:
            case 500:
            case 400012:
                return Promise.reject( { msg: '系统异常' })
            case 410000:
            case 410001:
            case 410002:
                localStorage.clear()
                // removeCookies('hlzw_canyin_token')
                router.replace({ path: '/' })
                break
            default:
                break
        }
    },
    error=>{
        if (typeof error.response == "undefined") {
            Message.error("服务器异常");
        }
        return Promise.reject(error);
    }

)

export default service;
