import request from '@/utils/request'

// 首页相关接口
export function cate_list (data) {
    return request({
        url: '/index/cate_list',
        method: 'post',
        data
    })
}

export function indexBanner (data) {
    return request({
        url: '/index/indexBanner',
        method: 'post',
        data
    })
}

export function indexData (data) {
    return request({
        url: '/index/indexData',
        method: 'post',
        data
    })
}

//栏目下banner图
export function cateBanner (data) {
    return request({
        url: '/index/cateBanner',
        method: 'post',
        data
    })
}

// 底部导航数据
export function configshow (data) {
    return request({
        url: '/config/show',
        method: 'post',
        data
    })
}

// 加盟强叔
export function joinindex (data) {
    return request({
        url: '/join/index',
        method: 'post',
        data
    })
}

//在线留言
export function indexonline (data) {
    return request({
        url: '/index/online',
        method: 'post',
        data
    })
}

//门店分布列表
export function storeindex (data) {
    return request({
        url: '/store/index',
        method: 'post',
        data
    })
}

// 新闻中心
export function newInfo (data) {
    return request({
        url: '/news/newInfo',
        method: 'post',
        data
    })
}

//加入我们
export function join_us (data) {
    return request({
        url: '/join/join_us',
        method: 'post',
        data
    })
}

// 搜索门店
export function get_lng (data) {
    return request({
        url: '/store/get_lng',
        method: 'post',
        data
    })
}

// 招聘选项
export function job_list (data) {
    return request({
        url: '/join/job_list',
        method: 'post',
        data
    })
}

// 省列表
export function province_list (data) {
    return request({
        url: '/join/province_list',
        method: 'post',
        data
    })
}

// 城市列表
export function get_city (data) {
    return request({
        url: '/join/get_city',
        method: 'post',
        data
    })
}

// 城市列表
export function sign (data) {
    return request({
        url: '/join/sign',
        method: 'post',
        data
    })
}

// 类型列表
export function type_list (data) {
    return request({
        url: '/product/type_list',
        method: 'post',
        data
    })
}

// 菜品展示
export function product_list (data) {
    return request({
        url: '/product/product_list',
        method: 'post',
        data
    })
}

// 新闻详情
export function new_xq (data) {
    return request({
        url: '/news/new_xq',
        method: 'post',
        data
    })
}

// 全部荣耀
export function glory_xq (data) {
    return request({
        url: '/index/glory_xq',
        method: 'post',
        data
    })
}