<template>
  <div class="header-top" :style="[setStyles]"  >
      <div class="hearder_box">
          <div class="nav_box">
              <div id="iconClass" class="logo_box"><img src="../assets/yushen/phone.png"></div>
              <div class="menu_box">
                  <!-- <div @click="menuClick('shouye')" class="item_menu" >
                      <img src="../assets/yushen/shouye.png" >
                  </div>
                  <div @click="menuClick('pinpai')" class="item_menu" >
                      <img src="../assets/yushen/pinpai.png" >
                  </div>
                  <div @click="menuClick('hezuo')" class="item_menu" >
                      <img src="../assets/yushen/hezuo.png" >
                  </div> -->
                  <img style="width:84px;height:79px;margin-top:32px" :src="info.web_site_logo">
                  <!-- <div @click="menuClick('caipin')" class="item_menu" >
                      <img src="../assets/yushen/caipin.png" >
                  </div>
                  <div @click="menuClick('mendian')" class="item_menu" >
                      <img src="../assets/yushen/mendian.png" >
                  </div>
                  <div @click="menuClick('xinwen')" class="item_menu" > 
                      <img src="../assets/yushen/xinwen.png" >
                  </div> -->
              </div>
              <div class="right-logo-box"><img src="../assets/yushen/right-logo.png"></div>
          </div>
      </div>
  </div>
</template>

<script>
export default { 
  name: 'HelloWorld',
  props:{
    scale:{
      type:Number,
      
    },
    info:{
      type:Object,
      default:{
        web_site_logo:"@/assets/yushen/logo.png"
      }
    } 
  },
  computed: {
    setStyles() {
      return {
        '--scale': this.scale 
      }
    }
  },
  mounted() {
  },
  methods: {
    menuClick(e){
        location.href = "#"+e
    }
  }
}
</script>

<style scoped lang="scss"> 
    .header-top{
      position:fixed;z-index:999;
      width:100%;
    }
    .hearder_box{
      width: 100%;
      height: 50px;
      background-image: url("../assets/yushen/top.png");
      background-size: 100% 100px;
      background-repeat: no-repeat;
      box-shadow:  0 1px 2px 3.5px rgba(0,0,0,0.8);-webkit-box-shadow: 0 1px 2px 3.5px rgba(0,0,0,0.8);  //Google Chrome-moz-box-shadow: 0 0px 3px 4px rgba(64,93,230,0.6)
      // box-shadow:  -3.8px -1.2px 0.3px 5.7px rgba(0,0,0,0.8);-webkit-box-shadow:  -3.8px -1.2px 0.3px 5.7px rgba(0,0,0,0.8);  //Google Chrome-moz-box-shadow: 0 0px 3px 4px rgba(64,93,230,0.6)
      
      // background: #025332;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nav_box{
      width: 95%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around
    }
    
    .logo_box{
      object-fit: cover;
      cursor: pointer;
      width: calc(323px * var(--scale) * 2); 
      height: calc(51px * var(--scale) * 2); 
    }
    .right-logo-box{
      width: calc(323px * var(--scale) * 2); 
      height: calc(51px * var(--scale) * 2); 
      text-align: center;
      object-fit: cover;
      cursor: pointer;
    }
    .logo_box img{
      width: calc(323px * var(--scale) * 2); 
      height: calc(51px * var(--scale) * 2); 
    }
    .right-logo-box img{
      width: calc(194px * var(--scale) * 2.5); 
      height: calc(45px * var(--scale) * 2.5); 
    }
    .menu_box{
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .item_menu{
      width: calc(140px * var(--scale)); 
      height: 80px;
      font-size:30px;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item_menu img{
      width: 74px;
      height: 36px;
      cursor: pointer;
    }
</style>
